<div (click)="clickOutsideCard()">
    <ng-container *ngFor="let item of objectKeys(renderedFlyoutItems)">
      <ng-container *ngTemplateOutlet="flyoutTpl; context: {$implicit: renderedFlyoutItems[item]}"></ng-container>
    </ng-container>
    <div [ngClass]="{
      air__menuLeft: true,
      air__menuLeft__mobileToggled: isMobileMenuOpen,
      air__menuLeft__toggled: isMenuCollapsed,
      air__menuLeft__unfixed: isMenuUnfixed,
      air__menuLeft__shadow: isMenuShadow,
      air__menuLeft__flyout: flyoutMenuType === 'flyout',
      air__menuLeft__compact: flyoutMenuType === 'compact',
      air__menuLeft__blue: menuColor === 'blue',
      air__menuLeft__white: menuColor === 'gray' || menuColor === 'white',
      air__menuLeft__gray: menuColor === 'gray',
      air__menuFlyout__black: flyoutMenuColor === 'dark' && flyoutMenuType !== 'default',
      air__menuFlyout__white: flyoutMenuColor === 'white' && flyoutMenuType !== 'default',
      air__menuFlyout__gray: flyoutMenuColor === 'gray' && flyoutMenuType !== 'default'
    }">
      <div class="air__menuLeft__outer">
        <a href="javascript: void(0);" class="air__menuLeft__mobileToggleButton" (click)="toggleMobileMenu()">
          <span></span>
        </a>
        <a href="javascript: void(0);" class="air__menuLeft__toggleButton" (click)="toggleMenu()">
          <span></span>
          <span></span>
        </a>
        <a href="javascript: void(0);" class="air__menuLeft__logo">
          <div class="air__menuLeft__logo__letter">
            <!-- <a href="" [ngSwitch]="menuColor" *ngIf="!customUrlLogo && !isTeamUrl">
              <ng-container *ngSwitchCase="'dark'">
                <img *ngIf="!darkLogo" src="assets/images/logo/qr-product-logo.png" alt="">
                <img *ngIf="darkLogo"
                  src="{{apiUrl}}/logo-dark/{{userSetting.user_id}}/{{userSetting.setting_id}}/''?{{dynamicDarkLogoNumber}}"
                  alt="">
              </ng-container>
              <ng-container *ngSwitchDefault>
                <img *ngIf="userSetting.logo" id="1"
                  src="{{apiUrl}}/logo/{{userSetting.user_id}}/{{userSetting.setting_id}}/''?{{dynamicLogoNumber}}"
                  alt="">
                <img *ngIf="!userSetting.logo" id="2" (error)="errorHandler($event)" src="{{src}}" alt="">
              </ng-container>
  
            </a> -->
            <a href="" >
              <img src="{{src}}" *ngIf="menuColor != 'dark'" (error)="errorHandler($event)" alt="">
              <img src="{{darkSrc}}" *ngIf="menuColor == 'dark'" (error)="errorDarkImageHandler($event)" alt="">
            </a>
          </div>
        </a>
        <div class="nz-divider">
          <nz-divider class="mt-1 mb-1"></nz-divider>
        </div>
        <perfect-scrollbar>
          <div class="air__menuLeft__container">
            <button class="mt-3 newBtn ant-new-btn d-flex align-items-center justify-content-center" *ngIf="!isReviewer"
              [class.btn-width]="isMenuCollapsed" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu"
              nzPlacement="bottomLeft" (click)="isWorksapce();">
              <i class="fe fe-plus"></i><span class="ml-1">New</span></button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu class="newDropdownMenu">
                <li nz-menu-item *ngIf="!isTeamUrl"
                  (click)="currentPage == 'dashboard' && userPermission.allowWorkspace && createFolder('workspace', 1)"
                  [nzDisabled]="selectedFolderObj.folderId !== 0 || currentPage != 'dashboard'"
                  [ngClass]="{'opacity':currentPage != 'dashboard', 'opacity2': !userPermission.allowWorkspace, 'isAuthorized': selectedFolderObj.folderId !== 0 || isFolderId != 0}">
                  <i class='fe fe-airplay mr-3'></i>
                  Workspace
                </li>
                <li [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer" [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
                  nz-menu-item (click)="currentPage == 'dashboard' && createFolder('folder', 0)"> <i
                    class="fe fe-folder mr-3"></i> Folder
                </li>
                <li [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer" [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
                  nz-menu-item (click)="(currentPage == 'dashboard' && teamAllowedSpace) || (currentPage == 'dashboard' && isReviewer) || (currentPage == 'dashboard' && selectedFolderObj.folderId > 0) ? fileInput.click() : ''"> <i
                    class="fe fe-upload mr-3"></i> File Upload
                </li>
                <li [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer" [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
                  nz-menu-item (click)="currentPage == 'dashboard' &&  createWebproof()"> <i
                    class="fe fe-layers mr-3"></i> Website</li>
              </ul>
            </nz-dropdown-menu>
            <ul class="air__menuLeft__list">
              <ng-container *ngFor="let item of menuData">
                <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
                <ng-container *ngTemplateOutlet="submenuTpl; context: {$implicit: item}"></ng-container>
                <ng-container *ngTemplateOutlet="categoryTpl; context: {$implicit: item}"></ng-container>
              </ng-container>
            </ul>
            <nz-divider></nz-divider>
            <div class="storage">
              <qr-storage [isMenuCollapse]="isMenuCollapsed"></qr-storage>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <a href="javascript: void(0);" class="air__menuLeft__backdrop" (click)="toggleMobileMenu()"></a>
    <ng-template #itemTpl let-item>
      <li *ngIf="item.children"  [ngClass]="{air__menuLeft__item__active: item.key === activeItem}"
        class="air__menuLeft__item cursor-pointer" [routerLink]="item.url ? item.url : 'javascript: void(0)'">
        <div class="dflex" style="padding: .6rem 0 0 1.6rem;">
          <div class="w-90">
            <qr-folder-tree></qr-folder-tree>
          </div>
          <!-- <div class="w-10">
            <i *ngIf="item.icon" class="air__menuLeft__icon rightficon" [ngClass]="item.icon"></i>
          </div> -->
        </div>
      </li>
      <ng-container *ngIf="!item.children && !item.category && !item.notForTeam || ( item.notForTeam && item.notForTeam !== isTeamUrl)">
        <li class="air__menuLeft__item cursor-pointer" (click)="toggleMobileMenu()"
          *ngIf="((item.title != 'Support / Feedback') || (item.title == 'Support / Feedback' && currentUrl.includes('quickreviewer.com')))"
          [ngClass]="{air__menuLeft__item__active: item.key === activeItem, 'd-none': item.title=='Dropbox' ? (isAuthenticatedDropBox == false || teamAllowedSpace == false ? false: false) : false,
          'd-none': item.title=='YouTube' ? (isAuthenticatedYoutube == false ? true: false) : false, 
          'd-none': item.title=='Box' ? (isAuthenticatedBox == false ? true: false) : false, 
          'isAuthorized': (item.title == 'Team' ? !userPermission.allowTeam : false) || (item.title == 'Mail Settings' ? !userPermission.allowCustomMail : false) || ( item.title == 'Whitelabel' ? !userPermission.allowWhitelabel : false) || ( (item.title == 'Workflow' ) ? !userPermission?.allowWorkFlow : false) || ( item.title == 'Workflow' && (userPermission?.allowWorkFlow < 1 )  ? true : false)}">
          <span [routerLink]="item.url ? item.url : 'javascript: void(0)'" class="air__menuLeft__link">
            <div class="d-flex w-100">
              <div class="w-75">
                <div class="d-flex w-100">
                  <div class="w-75">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="w-25 text-right">
                    <button type="button" [disabled]="!trashDocInfo && !trashFolderInfo" *ngIf="item.url=='/dashboard/trash'" class="ant-btn empty-btn px-2"
                      (click)="emptyTrash($event)">
                      Empty
                    </button>
                  </div>
                </div>
              </div>
              <div class="w-25 text-right">
                <i *ngIf="item.icon && item.title != 'Dropbox' && item.title != 'Box'" class="air__menuLeft__icon" [ngClass]="item.icon"></i>
                <i class="air__menuLeft__icon dropbox-icon" *ngIf="item.title == 'Dropbox'"></i>
                <img *ngIf="item.title == 'Box'" class="air__menuLeft__icon  " src="assets/images/logo/box_logo.svg">
                <span *ngIf="item.count" class="badge text-white bg-blue-light float-right mt-1 px-2">
                  
                  {{ item.count }}</span>
              </div>
            </div>
          </span>
        </li>
      </ng-container>
    </ng-template>
    <ng-template #submenuTpl let-item>
      <ng-container *ngIf="item.children">
        <li class="air__menuLeft__item air__menuLeft__submenu bg-white treeComponent"
          [ngClass]="{air__menuLeft__submenu__active: item.key === activeSubmenu}">
          <span class="air__menuLeft__link py-0">
            <div class="w-100">
            </div>
          </span>
        </li>
      </ng-container>
    </ng-template>
    <ng-template #categoryTpl let-item>
      <ng-container *ngIf="item.category">
        <li class="air__menuLeft__category">
          <span>{{ item.title }}</span>
          <span *ngIf="item.count" class="badge text-white bg-blue-light float-right mt-1 px-2">{{ item.count }}</span>
        </li>
      </ng-container>
    </ng-template>
    <ng-template #flyoutTpl let-item>
      <div @flyoutAnimation [ngStyle]="{
          left: item.itemDimensions.left + item.itemDimensions.width - 10 + 'px',
          top: item.itemDimensions.top + 'px'
        }" class="air__menuFlyout" [ngClass]="{
        air__menuFlyoutLeft: menuLayoutType === 'left',
        air__menuFlyout__black: flyoutMenuColor === 'dark',
        air__menuFlyout__white: flyoutMenuColor === 'white',
        air__menuFlyout__gray: flyoutMenuColor === 'gray'
      }">
        <ul class="air__menuLeft__list" (mouseenter)="handleFlyoutContainerOver(item.key)"
          (mouseleave)="handleFlyoutOut(item.key)">
          <ng-container *ngFor="let item of item.items">
            <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <!-- <input hidden type="file" id="sidebar-file" #fileInput ng2FileSelect [uploader]="uploader" multiple
      accept=".pdf,.tiff,.eps,.jpeg,.jpg,.png,.webm,.mp4,.mov,.avi,.zip,.psd,.ai,.pcm,.wav,.mp3,.aiff,.aac,.ogg,.wma,.m4a,.gif"> -->
      
<input hidden type="file" id="sidebar-file" #fileInput multiple
accept=".pdf,.tiff,.eps,.jpeg,.jpg,.png,.webm,.mp4,.mov,.avi,.zip,.psd,.ai,.pcm,.wav,.mp3,.aiff,.aac,.ogg,.wma,.m4a,.gif,.ppt,.pptx,
.glb,.gltf,.stl,.fbx,.obj,.usdz" (change)="addFileToUploadQueue($event.target.files)">
  </div>