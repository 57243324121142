
<div class="card-body custom-padding">
  <div class="title d-flex justify-content-between">
    <h5 class="card-title" *ngIf="data.length">{{data[0].workflowName}}</h5>
    
    <i (click)="closebtn()" class="fe fe-x crossIcon icon ng-star-inserted cursor-pointer"></i>
  </div>
  <nz-collapse class="test" nzAccordion [nzExpandIconPosition]="expandIconPosition">
    <nz-collapse-panel *ngFor="let panel of data; let i = index"
                        [nzHeader]=" 'Step ' + panel.stepNo +': '+ panel.stepName"
                        [nzActive]="panel.workflowStatus=='Ongoing'"
                        [nzExtra]="extraTpl">
      <div *ngFor="let item of panel.prodStatus"
            class="d-flex justify-content-between align-items-center my-2" style="font-size: 16px;">
        <div >
            <span > {{ item.name }}</span>
            <span class="icon ms-2 cursor-pointer" *ngIf="item.mustApprove" nz-tooltip nzTooltipTitle="Must Approve" [ngClass]="{'has-content':item.mustApprove}">
                M
            </span>
        </div>
        <span [ngStyle]="{'color': getItemStatusColor(item.status)}" >{{ item.status }}</span>
      </div>
      <ng-template #extraTpl>
        <span [ngStyle]="{'color': getStatusColor(panel.workflowStatus)}">{{ panel.workflowStatus }}</span>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>
</div>
